module controllers {
    export module tariff {

        interface ITariffGRIDetailSScope extends ng.IScope {
        }

        export class tariffGRIDetail {
            static $inject = ['$scope',
                '$rootScope',
                '$q',
                'generalService',
                'localeService',
                'userAccountService',
                'eventTypeService',
                'bsLoadingOverlayService',
                '$state',
                '$timeout',
                'aboutGTSService'
            ];

           
            IsLoading: boolean = false;


            
            constructor(
                private $scope: ITariffGRIDetailSScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private $q: ng.IQService,
                public generalService: interfaces.applicationcore.IGeneralService,
                public localService: interfaces.applicationcore.ILocaleService,
                public userService: interfaces.applicationcore.IUserAccountService,
                public eventTypeService: interfaces.applicationcore.IEventTypeService,
                public bsLoadingOverlayService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                private aboutGTSService: interfaces.applicationcore.IAboutGTSService,
            ) {
    
            }



         
        };

        angular.module("app").controller("tariffGRIDetail", controllers.tariff.tariffGRIDetail);
    }
}